export default {
    name: 'Nori Sushi',
    loading: 'Ładowanie',
    closed: {
        info1: 'Otwarcie 29.10.2020',
        info2: 'Zapraszamy wkrótce'
    },
    offline: {
        info1: 'Serwer jest niedostępny',
        info2: 'Prosimy o cierpliwość, pracujemy nad problemem'
    },
    nav: {
        profile: 'Twoje konto',
        admin: 'Admin',
        logout: 'Wyloguj się',
        login: 'Logowanie',
        registration: 'Rejestracja',
        orders: 'Twoje zamówienia',
        staffOrders: 'Zamówienia',
    },
    cover: {
        info1: 'Sushi skrojone dla Ciebie! Tradycyjne oraz fusion. Sushi wegańskie, bezglutenowe, fit! Przygotujemy je dla Ciebie z przyjemnością.',
        info2: 'Zapraszamy również na desery i specjały.',
    },
    soupOfTheDay: {
        header: 'Danie dnia',
    },
    seeMenu: {
        header: 'Sushi to nasza pasja',
        about: 'O restauracji',
        team: 'Nasz zespół',
        contact: 'Kontakt',
    },
    aboutUs: {
        restaurant: 'O restauracji',
        virtual: 'Wirtualny spacer',
        whyUs: {
            header: "Dlaczego Norisushi?",
        },
    },
    contact: {
        header: 'Kontakt',
        social: 'Dołącz do nas i śledź promocje sezonowe',
        welcome: 'Zapraszamy',
        apartment: 'Lokal: {apartment}',
        floor: 'Piętro: {floor}',
        staircase: 'Klatka: {staircase}',
        contact: 'Kontakt',
        zoli: "Żoliborz",
        wola: "Wola",
        hours: 'Godziny otwarcia',
        closed: 'Zamknięte',
    },
    menu: {
        header: 'Menu',
        closed: 'Składanie zamówień na dzień dzisiejszy jest niedostępne.<br/>Zapraszamy w godzinach otwarcia lub do zamówienia na przyszłe terminy!',
        closedLong: 'Niestety restauracja jest teraz nieczynna, zapraszamy w najbliższych godzinach otwarcia',
        adminClosed: 'Przepraszamy, aktualnie mamy zbyt dużo zamówień, spróbuj później',
        free_food: 'Za każde wydane 100zł dodajemy wybraną rolkę gratis :)',
        free_addons: 'Darmowe dodatki',
        disabledDate: 'Zamówienie na wybraną datę nie może zostać zrealizowane. W razie problemów prosimy o kotnakt telefoniczny.',
        differentMenuError: 'Próbujesz dodać dania z dwóch różnych menu. Jeżeli chcesz kontynuować Twój dotychczasowy koszyk zostanie wyczyszczony.',
        todaySpecial: 'W dniu dzisiejszym obowiązuje menu specjalne: {title}',
        specialMenuInfo: 'Obowiązuje na dzień {date}',
        specialMenuActive: 'Przygotowaliśmy dla Ciebie specjalne menu! Sprawdź!',
        specialMenuPerOrder: 'Na każdą godzinę obowiązuje limit zamówień, dlatego niektóre godziny mogą być niedostępne',
        specialMenuFull: 'Przepraszamy. Grafik zamówień jest już pełny.',
        containPromoItems: 'Promocja dotyczy produktów zamawianych na dzień dzisiejszy. W inne dni obowiązuje normalna cena.',
        mobile: {
            header: 'Wybierz kategorie',
            showDescription: 'Pokaż opis dania',
            showAllDishes: 'Pokaż wszystkie dania',
        },
    },
    cart: {
        addMore: 'Dodaj do zamówienia',
        addMoreClose: 'Zamknij',
        validationFields: {
            name: "Imię odbiorcy",
            email: 'Email',
            tel: 'Telefon kontaktowy',
            payment_method: 'Metoda płatności',
            order_type: 'Sposób odbioru zamówienia',
            address: 'Adres dostawy',
        },
        errors: {
            emptyFields: "Wypełnij wszystkie pola",
            deliveryNotPossible: "Nie spełniono warunków dostawy",
            dateNotSelected: "Wybierz godzinę dostawy",
            formData: "Brakujące pola: {missing}",
        },
    },
    order: {
        button: 'Zamów online',
        normalButton: 'Menu',
        phone: "Telefon",
        phone_zoli: 'Żoliborz: {phone}',
        phone_wola: 'Wola: {phone}',
        header: 'Twoje zamówienie',
        version: 'Wersja dania',
        addons: 'Dodatki',
        addToCart: 'Dodaj do zamówienia',
        summary: 'Podsumowanie',
        sum: 'Suma',
        orderPrice: 'Cena zamówienia',
        servicePrice: 'Opłata za serwis',
        discount: 'Rabat',
        deliveryPrice: 'Koszt dostawy',
        totalPrice: 'Łączna kwota',
        address: 'Adres dostawy',
        addAddress: 'Dodaj adres',
        editAddress: 'Edytuj adres',
        addDishComment: 'Dodaj komentarz do dania',
        dishComment: 'Komentarz do dania',
        delivery_unavailable: 'Dowóz niedostępny dla wybranej godziny. Sposób odbioru zamówienia zmieniony na: Odbiór osobisty',
        data: {
            header: 'Informacje o odbiorcy',
            name: 'Imię odbiorcy',
            email: 'Email',
            tel: 'Telefon kontaktowy',
            peopleAmount: 'Na ile osób?',
            peopleAmountPlaceholder: 'Na ile osób? (opcjonalnie)',
            nip: 'NIP',
            nipPlaceholder: 'NIP (opcjonalnie)',
            comment: 'Komentarz do zamówienia',
        },
        delivery_when: {
            header: 'Dostawa',
            modalHeader: 'Wybierz datę i godzinę dostawy',
            modalHeaderCallendar: 'Wybierz datę dostawy',
            modalHeaderTime: 'Wybierz godzinę dostawy',
            hourMainHeader: 'Godzina dostawy',
            hourHoursHeader: 'Wybierz godzinę',
            hourMinutesHeader: 'Wybierz minutę',
            changeDay: 'Zmień dzień',
            changeHour: 'Zmień godzinę',
            now: 'Jak najszybciej',
            select: 'Wybierz datę',
            selectSpecial: 'Wybierz godzinę',
            specialMenuDay: 'W wybrany dzień obowiązuje menu specjalne {title}',
            specialMenuDayButton: 'Przenieś mnie do {title}',
            blocked: 'Zamówienie zawiera dania z kategorii czasowych, które dostępne są tylko w zamówieniach na bieżąco',
        },
        payment_method: {
            header: 'Metoda płatności',
            cash: 'Gotówką przy odbiorze',
            card: 'Kartą przy odbiorze',
            tpay: 'Online przez tpay',
            pyszne_payment: 'Opłacone na pyszne.pl',
            uber_payment: 'Opłacone na Uber',
            glovo_payment: 'Opłacone na Glovo',
            wolt_payment: 'Opłacone na Wolt',
            only_points: 'Opłacone punktami',
            select_later: 'Wybierz później (stolik)',
        },
        order_type: {
            header: 'Sposób odbioru zamówienia',
            online: 'Dowóz',
            to_go: 'Odbiór osobisty',
            in_restaurant: 'Zjem w restauracji',
        },
        create: 'Złóż zamówienie',
        success: 'Pomyślnie złożono zamówienie',
        payButton: {
            tpay: 'Opłać zamówienie online'
        },
        status: {
            created: 'Oczekiwanie na płatność',
            queued: 'Oczekiwanie na zaakceptowanie przez restaurację',
            in_preparation: 'Zamówienie przyjęte. Twoje sushi jest właśnie przygotowywane!',
            ended: 'Zamówienie wyruszyło w drogę do Ciebie lub zostało odebrane osobiście. Dziękujemy!',
            canceled: 'Zamówienie anulowane',
            active_table: 'Aktywny stolik',
            delivery_when: 'Zamówienie zostanie zrealizowane w dniu {date}',
        },
        shortStatus: {
            created: 'Do opłacenia',
            queued: 'W kolejce',
            in_preparation: 'Przygotowywane',
            ended: 'Zakończone',
            canceled: 'Anulowane',
            active_table: 'Stolik',
        },
        index: {
            header: 'Twoje zamówienia'
        },
        estimated_delivery_time: 'Szacowana godzina dostawy: {time}',
        estimated_to_go_time: 'Zamówienie będzie gotowe do odbioru: {time}',
        promocode: {
            header: 'Mam kod promocyjny',
            button: 'Użyj',
            success: 'Zastosowano kod promocyjny, zostanie zużyty przy złożeniu zamówienia',
            errors: {
                null: 'Kod promocyjny nie istnieje',
                disabled: 'Kod promocyjny nieaktywny',
                used: 'Kod promocyjny został już wykorzystany',
                ended: 'Kod promocyjny stracił ważność',
                min_price: 'Nie została osiągnięta minimalna cena zamówienia',
                no_addon_table_end: 'Nie można dodać dodatków przy zamykaniu stolika',
                no_amount_table_start: 'Zniżki cenowe dodajemy przy zamykaniu stolika',
            }
        },
        error: {
            too_far: 'Adres poza obszarem dostawy',
            min_price: 'Minimalna wartość zamówienia przy tej odległości to {min}zł',
            min_price_special: 'Minimalna wartość zamówienia dla tego menu to {min}zł',
            free: 'Do zamówień powyżej {free}zł przy tej odległości, dostawa będzie darmowa'
        },
    },
    timepicker: {
        no_hours: 'Brak dostępnych godzin na wybrany dzień',
    },
    account: {
        header: 'Moje konto',
        personalData: {
            header: 'Dane osobowe',
            password: {
                header: 'Zmiana hasła',
                change: 'Zmień hasło',
                success: 'Pomyślnie zmieniono hasło',
                info1: 'Mocne hasło to podstawa bezpiecznego konta.',
                info2: 'W każdym momencie masz możliwość zmiany swojego hasła.'
            },
            data: {
                success: 'Pomyślnie ustawiono dane odbiorcy',
            },
            address: {
                success: 'Pomyślnie ustawiono adres dostawy',
            },
            marketing: 'Zgody marketingowe',
        },
    },
    invoice: {
        payment_method: {
            bank_transfer: 'Przelew',
            cash: 'Gotówka',
            online: 'Płatność online',
            card: 'Płatność kartą',
        },
    },
    auth: {
        login: {
            header: 'Logowanie',
            button: 'Zaloguj się'
        },
        registration: {
            header: 'Rejestracja',
            button: 'Zarejestruj się',
        },
        forgotPassword: 'Nie pamiętam hasła',
        sendReset: {
            header: 'Zresetuj hasło',
            button: 'Wyślij link do zresetowania hasła'
        },
        reset: {
            header: 'Zresetuj hasło',
            button: 'Resetuj hasło'
        },
        side: {
            register: {
                header: 'Witaj na {site}',
                info1: 'Na stronie {site} możesz w łatwy sposób zawalczyć o wielkie pieniądze.',
                info2: 'Wypełnij dowolny kupon gry.',
                info3: 'A to wszystko, siedząc wygodnie w fotelu!'
            },
            sendReset: {
                header: 'Zapomniałeś hasła?',
                info1: 'Podaj adres e-mail na którym zarejestrowane jest Twoje konto.',
                info2: 'Na podany adres e-mail zostanie wysłany link do zresetowania hasła.'
            },
            reset: {
                header: 'Resetowanie hasła',
                info1: 'Rozpocząłeś proces resetowania hasła',
                info2: 'Podaj nowe hasło, aby móc ponownie logować się na stronie.'
            }
        }
    },
    terms: {
        header: 'Regulamin',
    },
    privacy_policy: {
        header: 'Polityka prywatności',
    },
    deliveryPrice: {
        header: 'Koszty dostawy',
    },
    noGluten: {
        header: 'Sushi bez glutenu',
    },
    catering: {
        header: 'Catering',
    },
    messages: {
        login: 'Zalogowano pomyślnie!',
        logout: 'Wylogowano pomyślnie!',
        signup: 'Konto założone pomyślnie!',
        error: 'Błąd',
        mustLogin: 'Musisz się zalogować',
        accessDenied: 'Brak dostępu',
        onlyAdmin: 'Dostęp tylko dla admina',
        banned: 'Zostałeś zbanowany',
    },
    days: ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'],
    months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    form: {
        email: 'Email',
        password: 'Hasło',
        password2: 'Potwierdzenie hasła',
        passwordNew: 'Nowe hasło',
        passwordCurrent: 'Obecne hasło',
        terms: 'Oświadczam, że akceptuję <a href="/regulamin">regulamin</a> serwisu {site}',
        marketing: 'Chcę otrzymywać informację o promocjach na adres email',
        errors: {
            fieldRequired: 'Pole wymagane',
            email: {
                wrong: 'Podano niepoprawny adres email'
            },
            password: {
                min: 'Minimalna długość hasła to {min} znaków',
                max: 'Maksymalna długość hasła to {max} znaków',
                notSame: 'Podane hasła nie są takie same'
            },
            first_name: {
                min: 'Minimalna długość imienia to {min} znaków'
            },
            last_name: {
                min: 'Minimalna długość nazwiska to {min} znaków'
            },
            terms: 'Musisz potwierdzić regulamin'
        },
        save: 'Zapisz'
    },
    addressSelector: {
        header: 'Wybierz adres',
        info: 'Podaj ulicę i numer bloku (bez numeru mieszkania)',
        placeholder: 'Ulica i numer bloku',
        empty: 'Brak wyników, podaj poprawną ulicę.',
        results: 'Znalezione adresy (kliknij aby zaznaczyć)',
        apartment: 'Numer lokalu (opcjonalnie)',
        floor: 'Piętro (opcjonalnie)',
        staircase: 'Klatka (opcjonalnie)',
        button: 'Zatwierdź',
    },
    promocode: {
        kind: {
            discount: 'Obniżka ceny (%)',
            addon: 'Darmowa pozycja',
            amount: 'Obniżka ceny (kwota)',
            delivery: 'Darmowa dostawa',
        },
        max_use_amount: {
            once: 'Jedno użycie',
            once_per_user: 'Jedno użycia na konto',
            infinite: 'Nieograniczone',
        },
    },
    list: {
        showList: 'Pokaż listę',
        show: 'Pokaż',
        id: 'ID',
        user: 'Użytkownik',
        game: 'Gra',
        count: 'Ilość',
        status: 'Status',
        amount: 'Kwota',
        drawDate: 'Data losowania',
        date: 'Data',
        price: 'Cena',
        prize: 'Wygrana',
        type: 'Rodzaj',
        title: 'Tytuł',
        orderId: 'Numer zamówienia',
        address: 'Adres dostawy',
        filters: {
            header: 'Filtry',
            button: 'Filtruj',
            loading: 'Wczytuje dane',
            empty: 'Brak wyników',
            status: 'Status'
        },
        pagination: {
            prev: 'Poprzednia',
            next: 'Następna',
            page: 'Strona',
            of: 'z {pageCount}'
        }
    },
    sayyes: 'Tak',
    sayno: 'Nie',
    soon: 'Wkrótce',
}