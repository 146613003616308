import axios from 'axios'
import { defaultReqInterceptors, defaultResInterceptors, defaultResInterceptorsError } from '../main'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.params = {
    place: localStorage.getItem('place') || 'zoliborz'
}
axios.interceptors.request.use(config => defaultReqInterceptors(config))
axios.interceptors.response.use(
    response => defaultResInterceptors(response),
    error => defaultResInterceptorsError(error)
)

// Page data
export function loadData() {
    return axios.get('/load_data.json')
}
export function loadMenu() {
    return axios.get('/load_menu.json')
}
export function getActiveHappyHours(restaurant) {
    return axios.get('/happy_hours.json?restaurant=' + restaurant.toString())
}

// Auth
export function registration(data) {
    return axios.post('/auth.json', data)
}

export function sendPasswordResetLink(data) {
    return axios.post('/auth/password.json', data)
}

export function resetPassword(data) {
    return axios.put('/auth/password.json', data)
}

export function login(data) {
    return axios.post('/auth/sign_in.json', data)
}

export function logout() {
    return axios.delete('/auth/sign_out.json')
}

export function validateToken() {
    return axios.get('/auth/validate_token.json')
}

export function updateUserData(data) {
    return axios.put('/auth.json', data)
}

// SoupOfTheDay
export function getSoupOfTheDay() {
    return axios.get('/soup_of_the_day.json')
}

// Menu
export function getMenu() {
    return axios.get('/menu.json')
}

// Order
export function getOrder(id, token = null) {
    let query = token ? '?token=' + token : ''
    return axios.get('/orders/' + id + '.json' + query)
}

export function createOrder(params) {
    return axios.post('/orders.json', { order: params })
}

export function getOrders() {
    return axios.get('/orders.json')
}

export function getOrderAddMore(price, dishes, slug = "normal") {
    return axios.get('/orders/get_add_mores.json?price=' + price + '&dishes=' + dishes + '&slug=' + slug)
}

// Promocode
export function redeemCode(code, price, table_status) {
    return axios.get('/redeem/' + code + '.json?price=' + price + '&table_status=' + table_status)
}

// Special menu disabled time interval
export function getDateTimePickerInfo(params) {
    return axios.get('/datetimepicker_info.json', { params: {...axios.defaults.params, ...params} })
}
export function getDateTimePickerEnabledHours(params) {
    return axios.get('/datetimepicker_enabled_hours.json', { params: {...axios.defaults.params, ...params} })
}
export function getDateTimePickerDeliveryAvailable(params) {
    return axios.get('/check_date_delivery_available.json', { params: {...axios.defaults.params, ...params} })
}

//Wywalić do straffApi
export function getStaffOrders() {
    return axios.get('/orders/staff_index.json')
}
export function getStaffOrdersActive() {
    return axios.get('/orders/staff_index_active.json')
}
export function getStaffOrdersEnded(filters) {
    return axios.get('/orders/staff_index_ended.json', { params: {...axios.defaults.params, ...filters} })
}
export function getStaffOrdersFuture() {
    return axios.get('/orders/staff_index_future.json')
}
export function getStaffOrdersCash() {
    return axios.get('/orders/staff_index_cash.json')
}
export function getStaffOrdersCashReturned() {
    return axios.get('/orders/staff_index_cash_returned.json')
}
export function getStaffOrdersToPrint() {
    return axios.get('/orders/staff_index_to_print.json')
}

//Wywalić do straffApi
export function getStaffOrdersStatus() {
    return axios.get('/orders/status.json')
}
//Wywalić do straffApi
export function getStaffOrder(id) {
    return axios.get('/orders/' + id + '/staff_show.json')
}
//Wywalić do straffApi
export function updateStaffOrder(id, params) {
    return axios.put('/orders/' + id + '/staff_update.json', { order: params })
}
//Wywalić do straffApi
export function deleteStaffOrderDish(id, dish_id) {
    return axios.put('/orders/' + id + '/staff_delete_dish.json', { dish_id })
}
//Wywalić do straffApi
export function cancelStaffOrder(id) {
    return axios.delete('/orders/' + id + '.json')
}
//Wywalić do straffApi
export function getPrintData(id) {
    return axios.get('/orders/' + id + '/print.json')
}
//Wywalić do straffApi
export function staffRedirectOrder(id) {
    return axios.put('/orders/' + id + '/staff_redirect_order.json')
}
//Wywalić do straffApi
export function staffAcceptOrder(id, params) {
    return axios.put('/orders/' + id + '/staff_accept_order.json', params)
}
//Wywalić do straffApi
export function staffMarkEnded(id, courierCash = 0) {
    return axios.put('/orders/' + id + '/staff_mark_ended.json', { order: { courier_cash: courierCash } })
}
//Wywalić do straffApi
export function markReceiptToReprint(id) {
    return axios.put('/orders/' + id + '/reprint_receipt.json')
}
export function markOrderToReprint(id) {
    return axios.put('/orders/' + id + '/reprint_order.json')
}
export function markShortToReprint(id) {
    return axios.put('/orders/' + id + '/reprint_short.json')
}
//Wywalić do straffApi
export function stopReceiptPrint(id) {
    return axios.put('/orders/' + id + '/stop_receipt_print.json')
}
//Wywalić do straffApi
export function stopAllReceiptPrint() {
    return axios.put('/orders/stop_all_receipt_print.json')
}
//Wywalić do straffApi
export function setCourierCashReturned(id) {
    return axios.put('/orders/' + id + '/staff_set_cash_returned.json')
}

// UBER
export function getUberStoreStatus(restaurant = 'NovitusOnline') {
    return axios.get('/uber_store_status.json?restaurant=' + restaurant)
}
export function setUberStorePause(pause_until, restaurant = 'NovitusOnline') {
    return axios.post('/uber_store_pause.json', { pause_until: pause_until, restaurant: restaurant })
}
export function setUberStoreActivate(restaurant = 'NovitusOnline') {
    return axios.post('/uber_store_activate.json', { restaurant: restaurant })
}
export function getUberStoreOrders(filters, restaurant = 'NovitusOnline') {
    return axios.get('/uber_store_orders.json?restaurant=' + restaurant, { params: {...axios.defaults.params, ...filters} })
}
export function cancelUberStoreOrder(params, restaurant = 'NovitusOnline') {
    return axios.post('/cancel_uber_store_order.json', { ...params, restaurant: restaurant })
}
export function hideUberOrdersErrors(restaurant = 'NovitusOnline') {
    return axios.post('/hide_uber_orders_errors.json', { restaurant: restaurant })
}

export function getUberDeliveryStoreStatus() {
    return getUberStoreStatus('Delivery')
}
export function getUberKalmarStoreStatus() {
    return getUberStoreStatus('Kalmar')
}

// GLOVO
export function getGlovoMenu(restaurant) {
    return axios.get('/glovo_menu.json?extra=1&restaurant=' + restaurant)
}
export function changeCategoryGlovoName(id, params) {
    return axios.post('/change_category_name/' + id + '.json', params)
}
export function changeCategoryGlovoOrder(params) {
    return axios.post('/change_category_glovo_order.json', params)
}
export function changeCategoryGlovoAvailability(params) {
    return axios.post('/change_category_glovo_availability.json', params)
}
export function uploadGlovoMenu(restaurant) {
    return axios.get('/upload_glovo_menu.json?restaurant=' + restaurant)
}
export function editGlovoProduct(params) {
    return axios.post('/edit_glovo_product.json', params)
}
export function changeGlovoCategoryImg(id, params, restaurant) {
    return axios.post('/change_category_glovo_img/' + id + '.json?restaurant=' + restaurant, params)
}
export function changeGlovoDishImg(id, params, restaurant) {
    return axios.post('/change_dish_glovo_img/' + id + '.json?restaurant=' + restaurant, params)
}
export function getGlovoStoreStatus(restaurant) {
    return axios.get('/glovo_store_status.json?restaurant=' + restaurant)
}
export function setGlovoStorePause(until, restaurant) {
    return axios.post('/glovo_store_pause.json', { restaurant, until: until })
}
export function setGlovoStoreActivate(restaurant) {
    return axios.post('/glovo_store_activate.json?restaurant=' + restaurant)
}

// Wolt
export function getWoltStoreStatus(restaurant) {
    return axios.get('/wolt/store_status.json?restaurant=' + restaurant)
}
export function getWoltMenu(restaurant, extra = true) {
    const params = {
        restaurant
    };
    if (extra) {
        params["extra"] = 1;
    }
    const query = new URLSearchParams(params);
    return axios.get('/wolt/wolt_menu.json?' + query)
}
export function toggleWoltDeliveryMethods(restaurant) {
    return axios.put('/wolt/toggle_delivery_methods.json?restaurant=' + restaurant)
}
export function changeWoltMenuPrices(data) {
    return axios.post('/wolt/change_prices.json', data)
}
export function setWoltCategoriesAvailability(restaurant, categories) {
    return axios.post('/wolt/activate_categories.json?restaurant=' + restaurant, { categories })
}
export function disableWoltCategory(id, restaurant) {
    return axios.put('/wolt/' + id + '/deactivate_category.json?restaurant=' + restaurant)
}
export function refreshWoltCategory(id, restaurant) {
    return axios.put('/wolt/' + id + '/refresh_category.json?restaurant=' + restaurant)
}
export function changeWoltCategoriesOrder(params) {
    return axios.put('/wolt/change_categories_order.json', params)
}
export function changeWoltDishesOrder(params) {
    return axios.put('/wolt/change_dishes_order.json', params)
}
export function setWoltCategoryData(id, params) {
    return axios.put('/wolt/' + id + '/set_category_data.json', params)
}
export function setWoltDishData(id, params) {
    return axios.put('/wolt/' + id + '/set_dish_data.json', params)
}
export function setWoltOptionData(id, params) {
    return axios.put('/wolt/' + id + '/set_option_data.json', params)
}
// # TODO: BLOCK WOLT ORDERS
export function getWoltOrders(restaurant) {
    return axios.get('/orders/wolt_index.json?restaurant=' + restaurant)
}
export function markWoltAsOffline(restaurant) {
    return axios.put('/wolt/mark_as_offline.json', {restaurant})
}
export function markWoltAsOnline(restaurant) {
    return axios.put('/wolt/mark_as_online.json', {restaurant})
}
export function cancelWoltStoreOrder(params, restaurant = 'NovitusOnline') {
    return axios.post('/wolt/cancel_order.json', { ...params, restaurant: restaurant })
}
export function hideAutoacceptedOrder(uid) {
    return axios.post('/wolt/hide_autoaccepted_order.json', { uid })
}
export function hideCanceledOrder(uid) {
    return axios.post('/wolt/hide_canceled_order.json', { uid })
}